/* 
  CSS to have a nice PDF looking HTML page 
  and some configuration for the PDF generation
*/

@page {
    margin: 0;
}
#pdf {
    margin: 0;
}
.sheet {
    margin: 0;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
    page-break-after: always;
}

/** Paper sizes **/
#pdf.A3 .sheet {
    width: 297mm;
    height: 419mm;
}
#pdf.A3.landscape .sheet {
    width: 420mm;
    height: 296mm;
}
#pdf.A4 .sheet {
    width: 210mm;
    height: 296mm;
}
#pdf.A4.landscape .sheet {
    width: 297mm;
    height: 209mm;
}
#pdf.A5 .sheet {
    width: 148mm;
    height: 209mm;
}
#pdf.A5.landscape .sheet {
    width: 210mm;
    height: 147mm;
}
#pdf.letter .sheet {
    width: 216mm;
    height: 279mm;
}
#pdf.letter.landscape .sheet {
    width: 280mm;
    height: 215mm;
}
#pdf.legal .sheet {
    width: 216mm;
    height: 356mm;
}
#pdf.legal.landscape .sheet {
    width: 357mm;
    height: 215mm;
}

/** Padding area **/
.sheet.padding-10mm {
    padding: 10mm;
}
.sheet.padding-15mm {
    padding: 15mm;
}
.sheet.padding-20mm {
    padding: 20mm;
}
.sheet.padding-25mm {
    padding: 25mm;
}

/** For screen preview **/
@media screen {
    #pdf {
        background: #e0e0e0;
        padding: 5mm;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 5mm;
    }
    .sheet {
        background: white;
        box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
        /* margin: auto; */
    }
}

/** Fix for Chrome issue #273306 **/
@media print {
    #pdf.A3.landscape {
        width: 420mm;
    }
    #pdf.A3,
    #pdf.A4.landscape {
        width: 297mm;
    }
    #pdf.A4,
    #pdf.A5.landscape {
        width: 210mm;
    }
    #pdf.A5 {
        width: 148mm;
    }
    #pdf.letter,
    #pdf.legal {
        width: 216mm;
    }
    #pdf.letter.landscape {
        width: 280mm;
    }
    #pdf.legal.landscape {
        width: 357mm;
    }
}

.pdf-input {
    /* border: 1px solid #333; */
    background-color: rgba(127, 178, 255, 0.2);
    font-size: inherit;
    color: inherit;
    font-weight: inherit;
    font-family: inherit;
    text-decoration: inherit;
    padding: 2px;
    margin: 1px;
    border-radius: 5px;
    width: 100%;
    overflow: hidden;
}

@media print {
    .pdf-invisible {
        display: none !important;
        height: 0 !important;
      }
    .pdf-input {
    border: none !important;
    }
}
